import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';

type PropTypes = {
  className?: string;
  title: string;
  subTitle: string;
};

export default function HfTitle({className, title, subTitle}: PropTypes) {
  return (
    <div className={clsx(styles.title, className)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
}
