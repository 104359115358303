import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import application1Image from '../../images/section-application-1.jpg';
import application2Image from '../../images/section-application-2.jpg';
import application3Image from '../../images/section-application-3.jpg';
import application4Image from '../../images/section-application-4.jpg';
import application5Image from '../../images/section-application-5.jpg';
import decorationImage from '../../images/section-application-decoration.svg';

type PropTypes = {};
const APPLICATION_DATA = [
  {
    title: '物流运输',
    imageUrl: application1Image,
    link: '/solutions/category/2/page/1',
  },
  {
    title: '电子半导体',
    imageUrl: application2Image,
    link: '/electronic-solution',
  },
  {
    title: '消费包装',
    imageUrl: application3Image,
    link: '/solutions/category/3/page/1',
  },
  {
    title: '汽车工业',
    imageUrl: application4Image,
    link: '/solutions/category/1/page/1',
  },
  {
    title: '光伏锂电',
    imageUrl: application5Image,
    link: '/solutions/category/4/page/1',
  },
];

export default function SectionApplication({}: PropTypes) {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <HfTitle
          className={styles.gap}
          title="APPLICATION"
          subTitle="行业应用"
        />
        <Row align="stretch">
          {APPLICATION_DATA.map((application, index) => {
            const isSmallCard = index < 3;
            return (
              <Col key={application.title} md={isSmallCard ? 8 : 12} span={24}>
                <Link
                  className={clsx(styles.card, isSmallCard && styles.small)}
                  to={application.link || '#'}
                >
                  <img
                    className={styles.background}
                    src={application.imageUrl}
                    alt={application.title}
                  />
                  <div className={styles.title}>{application.title}</div>
                  <img
                    className={styles.decoration}
                    src={decorationImage}
                    alt=""
                  />
                  <div className={styles.mask}></div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
