import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import product1Image from '../../images/section-product-1.jpg';
import product2Image from '../../images/section-product-2.jpg';
import product3Image from '../../images/section-product-3.jpg';
import product4Image from '../../images/section-product-4.jpg';
import feat1Image from '../../images/section-product-feat-1.png';
import feat2Image from '../../images/section-product-feat-2.png';
import feat3Image from '../../images/section-product-feat-3.png';
import feat4Image from '../../images/section-product-feat-4.png';
import feat5Image from '../../images/section-product-feat-5.png';
import dividerImage from '../../images/section-product-divider.svg';
import {GAP_SIZE_2, GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';

type PropTypes = {};
const PRODUCT_DATA = [
  {
    title: '码类信息处理',
    imageUrl: product1Image,
  },
  {
    title: '机器视觉缺陷检测',
    imageUrl: product2Image,
  },
  {
    title: '工业生产视觉定位',
    imageUrl: product3Image,
  },
  {
    title: '机器人视觉引导',
    imageUrl: product4Image,
  },
];
const FEATURES_DATA = [
  {
    title: '动态实时重建',
    imageUrl: feat1Image,
  },
  {
    title: '高精度3D点云',
    imageUrl: feat2Image,
  },
  {
    title: '3D定位',
    imageUrl: feat3Image,
  },
  {
    title: '图形量化分析',
    imageUrl: feat4Image,
  },
  {
    title: '支持多码制',
    imageUrl: feat5Image,
  },
];

export default function SectionProduct({}: PropTypes) {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <HfTitle className={styles.gap} title="PRODUCT" subTitle="产品分类" />
        <Row
          className={styles.product}
          align="stretch"
          gutter={[0, GAP_SIZE_3]}
        >
          {PRODUCT_DATA.map(product => (
            <Col key={product.title} md={6} span={12}>
              <div className={styles.card}>
                <div className={styles.image}>
                  <img src={product.imageUrl} alt={product.title} />
                </div>
                <div className={styles.title}>{product.title}</div>
              </div>
            </Col>
          ))}
        </Row>
        <div className={styles.featureTitle}>产品特点</div>
        <Row
          className={styles.feat}
          justify="center"
          align="bottom"
          gutter={[0, GAP_SIZE_2]}
        >
          {FEATURES_DATA.map((feat, index) => (
            <>
              <Col md={4} span={8}>
                <div className={clsx(styles.icon, styles[`icon${index}`])}>
                  <img src={feat.imageUrl} alt={feat.title} />
                </div>
                <div className={styles.name}>{feat.title}</div>
              </Col>
              {index !== FEATURES_DATA.length - 1 && (
                <Col className={styles.divider} />
              )}
            </>
          ))}
        </Row>
      </div>
    </section>
  );
}
