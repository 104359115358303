// extracted by mini-css-extract-plugin
export var card = "index-module--card--8817z";
export var content = "index-module--content--quYkQ";
export var divider = "index-module--divider--lThyI";
export var feat = "index-module--feat--AX8Dw";
export var featureTitle = "index-module--feature-title--sgAHf";
export var gap = "index-module--gap--iF5vC";
export var icon = "index-module--icon--POyfN";
export var icon0 = "index-module--icon-0--mISTi";
export var icon1 = "index-module--icon-1--cKcAp";
export var icon2 = "index-module--icon-2--E4nDn";
export var icon3 = "index-module--icon-3--gfZWD";
export var icon4 = "index-module--icon-4--RSzwD";
export var image = "index-module--image--GRL3y";
export var name = "index-module--name--7QmO5";
export var product = "index-module--product--1Vx6d";
export var section = "index-module--section--T3IsK";
export var title = "index-module--title--SWgx2";