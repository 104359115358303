import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {GatsbyImage, StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import {GAP_SIZE_2, GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';
import ProductCard from '../product-card';

type ViewModel = Product[];
type PropTypes = {vm: ViewModel};

export default function SectionIntroduction({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <HfTitle
          className={styles.title}
          title="INTRODUCTION"
          subTitle="产品介绍"
        />
        <Row>
          {vm.map(item => (
            <Col sm={6} span={24}>
              <Link to="/category">
                <ProductCard vm={item} />
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}
