import {GatsbyImage} from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './index.module.scss';

type PropTypes = {
  vm: Product;
};

export default function ProductCard({vm}: PropTypes) {
  return (
    <div className={styles.card}>
      <GatsbyImage
        className={styles.image}
        image={vm.image?.imageFile?.childImageSharp.gatsbyImageData}
        alt={vm.name}
      />
      <div className={styles.name}>{vm.name}</div>
    </div>
  );
}
