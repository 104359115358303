import React from 'react';
import {graphql, PageProps} from 'gatsby';
import DefaultLayout from '../layouts/default';
import HfSliderHero, {
  ViewModel as SliderHeroViewModel,
} from '../components/hf-slider-hero';
import SectionProduct from '../components/section-product';
import hero1Image from '../images/index-hero-1.jpg';
import hero2Image from '../images/index-hero-2.jpg';
import hero3Image from '../images/index-hero-3.jpg';
import SectionApplication from '../components/section-application';
import SectionIntroduction from '../components/section-introduction';
import HfSeo from '../components/hf-seo';

const HERO_DATA: SliderHeroViewModel = [
  {
    title: '唯视智能',
    subTitle: ['一家以振兴中国机器视觉和传感科技为使命的创新型高科技公司'],
    imageUrl: hero1Image,
  },
  {
    title: '唯视智能',
    subTitle: [
      '唯视智能拥有优秀的科学家团队和卓越的市场营销团队，将机器学习、深度学习、机器视觉等技术带到生产线。',
    ],
    imageUrl: hero2Image,
  },
  {
    title: '唯视智能',
    subTitle: [
      '让机器视觉与人工智能惠及所有自动化行业，帮助企业实现智能化、自动化检测，降低成本切实提升企业产品质量和生产效率。',
    ],
    imageUrl: hero3Image,
  },
];

const IndexPage = ({
  data,
  location,
}: PageProps<{directus: {product: Product[]}}>) => {
  const products = data.directus.product || [];
  return (
    <DefaultLayout location={location}>
      <HfSliderHero vm={HERO_DATA} />
      <SectionProduct />
      <SectionIntroduction vm={products} />
      <SectionApplication />
    </DefaultLayout>
  );
};

export default IndexPage;

export const Head = () => <HfSeo title="唯视智能" />;

export const query = graphql`
  query IndexPageQuery {
    directus {
      product(sort: "sort", filter: {should_show_on_home_page: {_eq: true}}) {
        id
        name
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
      }
    }
  }
`;
